<template>
  <v-dialog v-model="dialog" :disabled="disabled" max-width="500">
    <template #activator="{ on }">
      <v-list color="transparent" dense flat>
        <v-list-item color="primary" v-on="on">
          <v-list-item-content>
            <template v-if="isValid && obAccount && itemType === 'code'">
              <v-list-item-title v-text="obAccount.code" />
              <v-list-item-subtitle v-text="obAccount.name" />
            </template>

            <v-list-item-title v-else-if="isValid && !!obOptionSelected">
              {{ obOptionSelected.text || obOptionSelected.name }}
            </v-list-item-title>

            <v-list-item-icon v-else>
              <icon-edit-box size="1.5em" />
            </v-list-item-icon>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <v-card v-if="dialog">
      <v-toolbar color="grey lighten-2" flat>
        <v-tabs v-model="tab">
          <v-tab
            v-for="itemType in itemTypeOptions"
            :key="itemType.value"
            :tab-value="itemType.value"
          >
            {{ itemType.text }}
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <ValidationObserver ref="observer" :vid="vid" slim>
        <template #default="{ invalid }">
          <v-card-text>
            <div class="pt-4">
              <account-code-select
                v-if="tab === 'code'"
                v-model="obItem.code"
                required
                @change="onSelectAccount"
              />

              <accounting-column-options-select
                v-if="tab === 'options'"
                v-model="obItem.field"
                @change="onSelectOption"
              />

              <account-type-select
                v-if="tab === 'accounttype'"
                v-model="obItem.field"
                prefixed
                @change="onSelectAccountType"
              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="onClose" v-text="$t('close')" />
            <v-spacer />
            <v-btn
              :disabled="invalid"
              color="primary"
              @click="onApply"
              v-text="$t('apply')"
            />
            <v-btn class="ml-4" color="error" icon @click="onResetColumn">
              <icon-trash />
            </v-btn>
          </v-card-actions>
        </template>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import {
  Account,
  AccountCollection,
  AccountingEntryColumnData,
  AccountTypeCollection,
  AccountTypeData,
} from "@planetadeleste/vue-mc-gw";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import { clone, isEmpty, startsWith, uniqueId } from "lodash";
import AccountingColumnOptionsSelect from "@/modules/accountingentries/components/AccountingColumnOptionsSelect.vue";
import { AccountingEntryColumnField, SelectItemData } from "@/types/utils";
import AccountTypeSelect from "@/modules/accounttypes/components/AccountTypeSelect.vue";

type AccountOptionSelected =
  | Partial<AccountTypeData | SelectItemData<AccountingEntryColumnField>>
  | undefined
  | null;

@Component({
  components: {
    AccountTypeSelect,
    AccountingColumnOptionsSelect,
    AccountCodeSelect,
  },
})
export default class AccountingColumnField extends Vue {
  @VModel({ type: Object }) obColumn!: Partial<AccountingEntryColumnData>;
  @Prop(Number) readonly column!: number;
  @Prop(Boolean) readonly disabled!: boolean;

  obAccountTypeCollection: AccountTypeCollection = new AccountTypeCollection();
  obItem: Partial<AccountingEntryColumnData> = {};
  obAccount: Account | undefined | null = null;
  obOptionSelected: AccountOptionSelected = null;
  dialog = false;
  tab = "code";

  get itemTypeOptions() {
    return [
      { text: this.$t("account.code"), value: "code" },
      { text: this.$t("accounting.options"), value: "options" },
      { text: this.$t("accounting.config"), value: "accounttype" },
    ];
  }

  get itemType(): string {
    if (!this.obItem.item_type) {
      return "code";
    }

    return ["code", "name"].includes(this.obItem.item_type)
      ? this.obItem.item_type
      : "options";
  }

  get vid() {
    return uniqueId("acf_form_observer");
  }

  get isValid() {
    return !isEmpty(this.obColumn.code) || !isEmpty(this.obColumn.field);
  }

  @Watch("dialog")
  onOpenClose(bValue: boolean) {
    if (bValue) {
      this.obItem = clone(this.obColumn);
    } else {
      this.obItem = {};
    }
  }

  @Watch("obColumn", { deep: true })
  onUpdateColumn() {
    this.onLoadAccount();
  }

  onClose() {
    this.dialog = false;
  }

  onApply() {
    this.obItem.item_type = this.tab === "accounttype" ? "options" : this.tab;
    this.$emit("input", this.obItem);
    this.onClose();
  }

  onSelectAccount(obData: Account) {
    this.obAccount = obData;
  }

  onSelectOption(obData: AccountOptionSelected) {
    this.obOptionSelected = obData;
  }

  onSelectAccountType(obData: AccountTypeData) {
    this.obOptionSelected = obData;
  }

  onResetColumn() {
    this.$emit("remove", this.column);
    this.onClose();
  }

  async onLoadAccount() {
    if (!this.isValid) {
      return;
    }

    switch (this.itemType) {
      case "code":
        await this.loadByCode();
        this.tab = this.itemType;
        break;

      case "options":
        await this.loadbyOptions();
        break;
    }
  }

  async loadByCode() {
    if (this.obAccount && this.obAccount.code === this.obColumn.code) {
      return;
    }

    const obAccountCollection = new AccountCollection();
    await obAccountCollection.filterBy({ code: this.obColumn.code }).fetch();

    if (obAccountCollection.size()) {
      this.obAccount = obAccountCollection.first();
    }
  }

  async loadbyOptions() {
    if (!this.obItem || !this.obItem.field) {
      return;
    }

    let sText = null;
    const sLangKey =
      this.obItem.field === "tax" ? "accounttax" : this.obItem.field;

    this.obAccountTypeCollection.each((obModel) => {
      if (this.obItem.field === `accounttype.${obModel.code}`) {
        sText = obModel.name;
      }
    });

    if (!sText) {
      sText = this.$t(`accounting.${sLangKey}`);
    }

    this.obOptionSelected = { text: sText, value: this.obItem.field };
    this.tab = startsWith(this.obItem.field, "accounttype.")
      ? "accounttype"
      : "options";
  }

  async mounted() {
    await this.obAccountTypeCollection.list();

    if (this.obColumn && !isEmpty(this.obColumn)) {
      this.obItem = clone(this.obColumn);
    }

    await this.onLoadAccount();
  }
}
</script>
