<template>
  <ValidationProvider
    ref="fieldAccountType"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="sValue"
        :error-messages="errors"
        :items="items"
        :menu-props="{ offsetY: true }"
        :success="valid"
        dense
        full-width
        hide-details="auto"
        outlined
        v-bind="$attrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  AccountTypeCollection,
  AccountTypeData,
} from "@planetadeleste/vue-mc-gw";
import { find, map, startsWith } from "lodash";
import { AccountingEntryColumnField, SelectItemData } from "@/types/utils";
import { arLabelOptions } from "@/modules/accountingentries/services/AccountingColumnOptions";

@Component
export default class AccountingColumnOptionsSelect extends Vue {
  @VModel({ type: String }) sValue!: string;
  @Prop(Boolean) readonly required!: boolean;

  obCollection: AccountTypeCollection = new AccountTypeCollection();

  get label() {
    return "accounting.options";
  }

  get items(): SelectItemData<AccountingEntryColumnField>[] {
    /*const arAccountTypeList = this.obCollection.length
      ? (map(
          this.obCollection.getModelList() as Partial<AccountTypeData>[],
          (obItem: Partial<AccountTypeData>) => {
            return {
              text: obItem.name,
              value: `accounttype.${obItem.code}`,
            };
          }
        ) as SelectItemData[])
      : [];*/

    return map(arLabelOptions(true), (obItem) => {
      obItem.text = this.$t(obItem.text) as string;

      return obItem;
    });

    // return concat(arItemList, arAccountTypeList);
  }

  onSelect(sValue: string) {
    let obItem: Partial<SelectItemData | AccountTypeData> | undefined =
      undefined;

    if (startsWith(sValue, "accounttype")) {
      const sCode = sValue.replace("accounttype.", "");
      obItem = this.obCollection.find({ code: sCode });
    } else {
      obItem = find(this.items, { value: sValue });
    }

    this.$emit("change", obItem);
  }

  async load() {
    if (this.obCollection.length) {
      return;
    }

    await this.obCollection.list();
  }

  async mounted() {
    await this.load();

    if (this.sValue) {
      this.onSelect(this.sValue);
    }
  }
}
</script>
